<template>
	<modal-drawer title="Actions" from-right @close="$emit('close')">
		<div style="padding: 1rem 1.25rem;">
			<ul class="viewer-actions unlist" style="width: 280px;">
				<li v-for="(action, label) in activeActions" :key="label">
					<button class="btn btn-default" :disabled="action.disabled" @click.prevent="action.action">
						<svg-icon :icon="action.icon" :pulse="action.icon === 'spinner'" fixed />
						{{ label }}
					</button>
				</li>
			</ul>

			<div v-if="activeStudy && !isStudyArchived" style="width: 280px; padding: 16px 0;">
				<div
					style="display: inline-flex; align-items: center; font-size: 0.9em; font-weight: 600; background: var(--banner-info-bg); box-shadow: -3px 0 0 var(--banner-info-border); padding: 12px 16px;"
				>
					<svg-icon icon="cloud-sync" style="margin-right: 8px; font-size: 1.75em;" />
					<span>
						Images are transferring to the cloud. Some operations will be unavailable until the transfer is
						complete.&nbsp;
						<a @click="showArchivingTip">More info &hellip;</a>
					</span>
				</div>
			</div>
		</div>
	</modal-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalDrawer from '@components/ModalDrawer'
import studyData from '@services/studyData'

export default {
	name: 'ViewerActionsDrawer',
	components: {
		ModalDrawer,
	},
	props: {
		name: {
			type: String,
			default: 'actions',
		},
	},
	data() {
		return {
			isBurningAnnotations: false,
		}
	},
	computed: {
		...mapGetters(['isCommunityUser', 'mprActive', 'activeSeries', 'activeStudy']),
		isStudyArchived() {
			return this.activeStudy && this.activeStudy.isArchived
		},
		areAllStudiesArchived() {
			return this.$store.state.viewer.studies.every(s => s.isArchived)
		},
		actions() {
			return {
				'Save Image': {
					icon: 'floppy-o',
					action: () => {
						this.$emit('save')
						this.$emit('close')
					},
					disabled: !this.activeStudy,
					hidden: this.isConsignerViewer,
				},
				'Copy Image': {
					icon: 'copy',
					action: () => {
						this.$emit('copy')
						this.$emit('close')
					},
					disabled: !this.activeStudy,
					hidden: this.isConsignerViewer,
				},
				'Print Image': {
					icon: 'print',
					action: () => {
						this.$emit('print')
						this.$emit('close')
					},
					disabled: !this.activeStudy,
					hidden: this.isConsignerViewer,
				},
				'Clear Annotations': {
					icon: 'close',
					action: () => {
						this.$emit('clear-annotations')
						this.$emit('close')
					},
					disabled: !this.activeStudy,
				},
				'Burn-In Annotations': {
					icon: this.isBurningAnnotations ? 'spinner' : 'floppy-o',
					action: () => {
						if (this.isBurningAnnotations) return
						/* eslint-disable vue/no-side-effects-in-computed-properties */
						this.isBurningAnnotations = true
						this.$emit('burn-in-annotations', () => {
							this.isBurningAnnotations = false
							this.$emit('close')
						})
					},
					disabled: !this.isStudyArchived,
					hidden: this.isPartnerViewer || this.isConsignerViewer || this.isEmailViewer || this.isCommunityUser,
				},
				'Email Images': {
					icon: 'email',
					action: () => {
						this.$emit('show-email-images')
						this.$emit('close')
					},
					disabled: !this.isStudyArchived,
					hidden: !this.isStudyReportViewer || this.isCommunityUser || this.mprActive,
				},
				'Download Study': {
					icon: 'cloud-download',
					action: () => {
						this.$emit('download-study')
						this.$emit('close')
					},
					disabled: !this.activeStudy || !this.areAllStudiesArchived,
					hidden: this.isConsignerViewer || this.isEmailViewer || this.mprActive,
				},
				'Import into Keystone': {
					icon: 'cloud-download',
					action: () => {
						this.$emit('import-into-keystone')
						this.$emit('close')
					},
					disabled: !this.isStudyArchived,
					hidden: !this.isEmailViewer,
				},
				'Download DICOM Images': {
					icon: 'cloud-download',
					action: () => {
						this.$emit('download-dicom-images')
						this.$emit('close')
					},
					disabled: !this.isStudyArchived,
					hidden: !this.isEmailViewer,
				},
				'Download JPEG Images': {
					icon: 'cloud-download',
					action: () => {
						this.$emit('download-jpeg-images')
						this.$emit('close')
					},
					disabled: !this.isStudyArchived,
					hidden: !this.isEmailViewer,
				},
			}
		},
		activeActions() {
			let result = {}
			for (let prop in this.actions) {
				let action = this.actions[prop]
				if (action.hidden !== true) {
					result[prop] = action
				}
			}
			return result
		},
		isStudyReportViewer() {
			return this.$route.name === 'viewer'
		},
		isPartnerViewer() {
			return this.$route.name === 'partner-study-viewer'
		},
		isConsignerViewer() {
			return this.$route.name === 'consigner-studies-viewer'
		},
		isEmailViewer() {
			return this.$route.name === 'email-share-consultant'
		},
	},
	methods: {
		showArchivingTip: studyData.showArchivingTip,
	},
}
</script>

<style lang="scss">
ul.viewer-actions {
	button {
		width: 100%;
		justify-content: flex-start;
	}
	li + li {
		margin-top: 4px;
	}
}
</style>
